import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import dateFormat from 'dateformat';
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import Slider from "react-slick"
import { navigate } from "gatsby"

// Images
import ImgPerson from "../../../images/person.jpg"
import blogImg1 from "../../../images/home/blog-img-01.webp"
import blogImg2 from "../../../images/home/blog-img-02.webp"
import blogImg3 from "../../../images/home/blog-img-03.webp"
import blogImg4 from "../../../images/home/blog-img-04.webp"
import ContactCard from "../../ContactCard/ContactCard"
import "./FilterBlockCate.scss"

import {InsightsCate} from "../../../queries/common_use_query"
import { removeDataAttributes } from "../../../comQueryStructure";
import { graphql, useStaticQuery } from "gatsby";

const LatestBlogsbyCategoryprop = (props) => {

  const property = useStaticQuery(graphql`
    query GetProperty {
          glstrapi {
    globalModule {
      data {
        attributes {
          commercial_rent {
            Button_name
            Contents
            Filter_by_Tag
            Hading
            Title
            id
            button_link
            blogs_title {
              data {
                attributes {
                  Title
                }
              }
            }
          }
          property_for_rent {
            Button_name
            Contents
            Filter_by_Tag
            Hading
            Title
            button_link
            blogs_title {
              data {
                attributes {
                  Title
                }
              }
            }
          }
          property_for_sale {
            Button_name
            Contents
            Filter_by_Tag
            Hading
            Title
            button_link
            blogs_title {
              data {
                attributes {
                  Title
                }
              }
            }
          }
          buy_property_new_project {
            Button_name
            Contents
            Filter_by_Tag
            Hading
            Title
            button_link
            blogs_title {
              data {
                attributes {
                  Title
                }
              }
            }
          }
        }
      }
    }
  }
      }
    `)
    const prop_data = removeDataAttributes(property)
    let Filter_by_Tag
    if(props?.propertyPage == "commercial_rent") {
       //props.Filter_by_Tag = prop_data.glstrapi.globalModule.commercial_rent
       Filter_by_Tag = prop_data.glstrapi.globalModule.commercial_rent
    }
    if(props?.propertyPage =="property_for_sale") {
      Filter_by_Tag = prop_data.glstrapi.globalModule.property_for_sale
    }
    if(props?.propertyPage =="property_for_rent") {
      Filter_by_Tag = prop_data.glstrapi.globalModule.property_for_rent
    }
    if(props?.propertyPage =="buy_property_new_project") {
      Filter_by_Tag = prop_data.glstrapi.globalModule.buy_property_new_project
    }

      
   //console.log("prop_data", Filter_by_Tag)

  let blogs = [];

  { Filter_by_Tag?.blogs_title?.length > 0 &&
    Filter_by_Tag?.blogs_title.map(item => blogs.push(item.Title))
   }
   let Filter_by_Tags = Filter_by_Tag?.Filter_by_Tag?Filter_by_Tag?.Filter_by_Tag:''
 
  const {loading, error, data} = InsightsCate("", 200);
  const otherInsightData = removeDataAttributes(data?.insights)



  let cateInsight = []
  otherInsightData?.length > 0 && otherInsightData?.map(item => {          
            if(blogs?.includes(item?.Title)){
              cateInsight.push(item)
            } 
  })
if(Filter_by_Tags) {
  otherInsightData?.length > 0 && otherInsightData?.map(item => {          
    if(Filter_by_Tags?.includes(item?.Tag)) {
      cateInsight.push(item)
    } 
})
}

//console.log("cateInsight", cateInsight)
if(cateInsight.length<=0) {
    otherInsightData?.length > 0 && otherInsightData?.slice(0,5).map(item => {
      cateInsight.push(item)
    })
}


const settings = {
  dots: true,
  speed: 800,
  slidesToShow: cateInsight.length>2?2:cateInsight.length,
  slidesToScroll: 1,
  arrows: false,
  mobileFirst:true,
  centerMode: false,
  swipeToSlide: true,
  variableWidth: cateInsight.length>2?true:false,
  responsive: [
    {
      breakpoint:1400,
       settings: {
        slidesToShow: cateInsight.length>2?2:cateInsight.length,
              slidesToScroll: 1,
              variableWidth: cateInsight.length>2?true:false,
          }
  },
    {
      breakpoint:1199,
       settings: {
        slidesToShow: cateInsight.length>1?2:cateInsight.length,
              slidesToScroll: 1,
              variableWidth: false
          }
  },
    {
        breakpoint: 767,
         settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: false
            }
    },      
]
}

  const openSharefrndformModal = (e) => { 
    navigate('/about/case-studies-and-insights/', { state: { slug: e} });
  }
 
  return (
    <div className="latest-blogs text-center text-xl-left">
    <Container>
      <Row>
         <Col xl={4}>
             <strong className="sub-title">{Filter_by_Tag?.Hading?Filter_by_Tag?.Hading:`Blogs and articles`}</strong>
             <h2 className="main-title">{Filter_by_Tag?.Title?Filter_by_Tag?.Title:`Insights from our expert team.`}</h2>
             <p className="main-desc d-md-block">{Filter_by_Tag?.Contents?Filter_by_Tag?.Contents:`With deep experience in the Qatar real estate market, we thrive on our insider knowledge. The Pearl Gates, one of the best real estate company in Qatar, shares valuable insights into the local property scene.`}</p>
             {Filter_by_Tag?.button_link?
             <a href={Filter_by_Tag?.button_link} className="btn btn-primary d-none d-xl-inline-block"><span>{Filter_by_Tag?.Button_name?Filter_by_Tag?.Button_name:`View More`}</span></a>
             :(
              Filter_by_Tag?.Filter_by_Tag?<a href="javascript:void(0)" onClick={() => openSharefrndformModal(Filter_by_Tag?.Filter_by_Tag)} className="btn btn-primary d-none d-xl-inline-block"><span>{Filter_by_Tag?.Button_name?Filter_by_Tag?.Button_name:`View More`}</span></a>
             :<a href={`/about/case-studies-and-insights/`} className="btn btn-primary d-none d-xl-inline-block"><span>{Filter_by_Tag?.Button_name?Filter_by_Tag?.Button_name:`View More`}</span></a>
              )             
             }
         </Col>         
        <Col xl={8}>
          <Slider {...settings} >
            {
              cateInsight?.map((item, index) => {
                var date_of_item = item.Date && dateFormat(item.Date, "dd mmmm yyyy");

                let category_name = []
                if(item?.categories?.length>0)   {
                  item?.categories?.map(cate => {
                    category_name.push(cate.category_name)
                  })
                }

                var split_tags = item.Tag ? item.Tag.split(', ') : '';
                let tagnames = category_name[0]?category_name[0]?.replaceAll('_', ' '):''
                if(Filter_by_Tag?.Filter_by_Tag) {
                  tagnames = item.Tag ? split_tags[0] : tagnames
                }

                return (
                  //  <div className="animated">
                    <ContactCard
                      cardImg={item.Tile_Image}
                      cardTitle={item.Title}
                      date={date_of_item}
                      cardTag={tagnames}
                      link_url={item.URL}
                      imagename={"insight.Tile_Image.tile_image"}
                      article_id={item.id} 
                      imagetransforms={item.ggfx_results}
                      externalLink={item.External_Link}
                      section="latest_blogs"
                    />
                  //  </div>
                )
              })
            }
          </Slider>
          {<a href="javascript:void(0)" onClick={() => openSharefrndformModal(Filter_by_Tag?.Filter_by_Tag)} className="btn btn-primary d-xl-none"><span>View More</span></a>}
        </Col>
        {/*  </div> */}
      </Row>
    </Container>
  </div>  
  )
}

export default LatestBlogsbyCategoryprop
